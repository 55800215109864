export default {
  methods: {
    prepareResponseWorkouts(workouts = [], products = []) {
      if (products.length == 0 || workouts.length == 0) {
        return workouts;
      }

      const temp = {};
      products.forEach((e) => {
        const i = workouts.findIndex((w) => w.id == e.workout_id);
        if (i >= 0) {
          if (!temp[workouts[i].id]) {
            temp[workouts[i].id] = [e];
          } else {
            temp[workouts[i].id].push(e);
          }
        }
      });

      for (const key of Object.keys(temp)) {
        workouts.find((w) => w.id == key).machineOrders = temp[key];
      }

      return workouts;
    },
  },
};
