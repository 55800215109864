<template>
  <div id="reserves">
    <v-card class="mb-3">
      <v-card-title>
        Бронирование
      </v-card-title>
    </v-card>
    <v-card>
      <workout-table :fetch-items="fetchWorkouts" />
    </v-card>
  </div>
</template>

<script>
import WorkoutTable from "@/components/workout/table/index.vue";
import workoutData from "@/mixins/WorkoutData";

export default {
  mixins: [workoutData],
  components: {
    WorkoutTable,
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
    };
  },

  methods: {
    async fetchWorkouts(payload) {
      const result = await this.$api.workout.workouts(
        payload.page,
        payload.itemsPerPage,
        payload.input
      );
      const workouts = result.data?.data?.workouts ?? {};
      let products = {};
      try {
        products = await this.$api.workout.workoutsProducts(
          workouts.data.map((e) => e.id)
        );
      } catch (e) {
        // Не увдлось получить продукты
      }

      return {
        data: this.prepareResponseWorkouts(
          workouts.data ?? [],
          products.data?.data?.getWorkoutsProducts ?? []
        ),
        paginatorInfo: workouts.paginatorInfo,
      };
    },
  },
};
</script>
