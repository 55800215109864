<template>
  <v-container>
    <workout-table :fetch-items="fetchWorkouts" />
  </v-container>
</template>

<script>
import WorkoutTable from "@/components/workout/table/index.vue";
import workoutData from "@/mixins/WorkoutData";

export default {
  mixins: [workoutData],
  components: {
    WorkoutTable,
  },
  props: {
    entityData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      items: [],
      total: 0,
      loading: false,
    };
  },
  methods: {
    async fetchWorkouts(payload) {
      const result = await this.$api.workout.workouts(
        payload.page,
        payload.itemsPerPage,
        {
          filter: { ...payload.input.filter, gym_id: this.entityData.id },
          orderBy: payload.input.orderBy,
        }
      );
      const workouts = result.data?.data?.workouts ?? {};
      let products = {};
      try {
        products = await this.$api.workout.workoutsProducts(
          workouts.data.map((e) => e.id)
        );
      } catch (e) {
        // Не увдлось получить продукты
      }

      return {
        data: this.prepareResponseWorkouts(
          workouts.data ?? [],
          products.data?.data?.getWorkoutsProducts ?? []
        ),
        paginatorInfo: workouts.paginatorInfo,
      };
    },
  },
};
</script>
